import { DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
import React, { useMemo } from 'react';
import Graph from 'react-graph-vis';

export const useNetworkTopologyChart = (
  deepScanTargets: DeepScanTargetDTO[],
  deepScanNetworkHosts?: DeepScanNetworkHostDTO[],
) => {
  const graph = useMemo(() => {
    return {
      nodes: (deepScanNetworkHosts || []).map((host) => {
        const target = deepScanTargets.find((t) => t.macAddress === host.macAddress);

        return {
          id: host.macAddress,
          label: host.hostName || host.ipAddress,
          title: host.ipAddress,
          color: host.macAddress == host.gatewayMacAddress ? '#F7E092' : target ? '#C2EDDB' : '#B9C3F5',
          shape: host.macAddress == host.gatewayMacAddress ? 'circle' : 'box',
          margin: 10,
        };
      }),
      edges: (deepScanNetworkHosts || [])
        .filter(
          (host) =>
            host.gatewayMacAddress && host.gatewayMacAddress !== '' && host.gatewayMacAddress !== host.macAddress,
        )
        .map((host) => {
          return {
            from: host.macAddress,
            to: host.gatewayMacAddress,
          };
        }),
    };
  }, [deepScanNetworkHosts, deepScanTargets]);

  const options = useMemo(() => {
    return {
      layout: {
        hierarchical: true,
      },
      edges: {
        color: '#000000',
      },
      height: '500px',
    };
  }, []);

  // const events = useMemo(() => {
  //   return {
  //     select: (event) => {
  //       const { nodes, edges } = event;
  //     },
  //   };
  // }, []);

  // const criticalVulnerabilities = useMemo(() => {
  //   const snapshotsByDate = (data || []).reduce<Record<string, MonitoringSnapshotDTO>>((acc, snapshot) => {
  //     const finished = Object.keys(snapshot.scanStatus || {}).every(
  //       (h) =>
  //         snapshot.scanStatus[h].telivyAgentInstalledAt ||
  //         (snapshot.scanStatus[h].system.scan_apps_os_version && snapshot.nmapScanStats[h]),
  //     );
  //     const message = finished ? 'Latest Scan' : 'Scan In Progress';
  //     const date = snapshot.isLive ? message : moment(snapshot.createdAt).format('Do MMMM YYYY');
  //     acc[date] = acc[date] || snapshot;

  //     return acc;
  //   }, {});

  //   const snapshotsDates = sortBy(
  //     Object.keys(snapshotsByDate).map((date) => {
  //       return { date, createdAt: snapshotsByDate[date].createdAt };
  //     }),
  //     (snapshot) => snapshot.createdAt,
  //   ).map((d) => d.date);

  //   let selectedAssetsFinal: string[] | undefined = undefined;
  //   if (onlyActive && snapshotsDates.length > 1) {
  //     const latestSnapshot = snapshotsDates[snapshotsDates.length - 2];
  //     selectedAssetsFinal = Object.keys(snapshotsByDate[latestSnapshot].nmapScanStats).filter((s) => {
  //       return (
  //         !snapshotsByDate[latestSnapshot].scanStatus ||
  //         !snapshotsByDate[latestSnapshot].scanStatus[s]?.system?.rescan_scheduled
  //       );
  //     });
  //   } else {
  //     selectedAssetsFinal = selectedAssets;
  //   }

  //   const summedByDate = sortBy(
  //     snapshotsDates.map((date, index) => {
  //       const prevDate = snapshotsDates[index - 1];

  //       const filteredAssets =
  //         (selectedAssetsFinal || []).length === 0
  //           ? Object.keys(snapshotsByDate[date].nmapScanStats)
  //           : selectedAssetsFinal || [];

  //       const assetsData = filteredAssets
  //         .filter((s) => {
  //           return !snapshotsByDate[date].scanStatus || !snapshotsByDate[date].scanStatus[s]?.system?.rescan_scheduled;
  //         })
  //         .map((s) => {
  //           const completed =
  //             snapshotsByDate[date].scanStatus &&
  //             (snapshotsByDate[date].scanStatus[s]?.telivyAgentInstalledAt ||
  //               (snapshotsByDate[date].scanStatus[s]?.system.scan_apps_os_version &&
  //                 snapshotsByDate[date].nmapScanStats[s]));

  //           return {
  //             name: completed ? s : `${s} *`,
  //             value: snapshotsByDate[date].nmapScanStats[s]?.cveCounts?.high || 0,
  //             previous: (prevDate && snapshotsByDate[prevDate].nmapScanStats[s]?.cveCounts?.high) || 0,
  //           };
  //         });

  //       const criticalCount = assetsData.reduce((a, b) => a + b.value, 0);
  //       const criticalCountPrev = prevDate
  //         ? Object.values(snapshotsByDate[prevDate].nmapScanStats).reduce((a, b) => a + b.cveCounts?.high || 0, 0)
  //         : 0;

  //       return {
  //         date,
  //         critical: criticalCount,
  //         criticalPrev: criticalCountPrev,
  //         assetsData,
  //         createdAt: snapshotsByDate[date].createdAt,
  //       };
  //     }),
  //     (snapshot) => snapshot.createdAt,
  //   );

  //   return summedByDate
  //     .reduce<RiskLineDataPoint[]>((acc, snapshot) => {
  //       acc.push({
  //         name: snapshot.date,
  //         score: snapshot.critical,
  //         distribution: ([] as any).concat(snapshot.assetsData, [
  //           { name: 'Total', value: snapshot.critical, previous: snapshot.criticalPrev },
  //         ]),
  //       });

  //       return acc;
  //     }, [])
  //     .slice(0, 365);
  // }, [data, selectedAssets, onlyActive]);

  const chart = useMemo(() => {
    return <Graph graph={graph} options={options} events={{}} />;
  }, [graph, options]);

  return {
    chart,
    // criticalVulnerabilities,
  };
};
