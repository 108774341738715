import { makeStyles } from '@material-ui/core/styles';
import { Badge } from 'components/Badge';
import { DeepScanNetworkHostDTO, DeepScanTargetDTO } from 'dtos/deep-scan';
import { useNetworkTopologyChart } from 'hooks/charts/useNetworkTopologyChart';
import React from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    ...TYPOGRAPHY.TITLE_3,
    color: COLORS.GREY_1,
    marginBottom: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  chartContainer: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    '&:first-child': {
      flex: 2,
    },
    border: `solid 1px ${COLORS.GREY_5}`,
  },
  chart: {
    height: '100%',
  },
  legend: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'left',
    '& > *': {
      marginRight: theme.spacing(1),
    },
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  deepScanTargets: DeepScanTargetDTO[];
  deepScanNetworkHosts?: DeepScanNetworkHostDTO[];
}

export const InventoryTopology = ({ deepScanTargets, deepScanNetworkHosts }: Props) => {
  const classes = useStyles();

  const { chart: networkTopologyChart } = useNetworkTopologyChart(deepScanTargets, deepScanNetworkHosts);

  return (
    <div className={classes.section}>
      <div className={classes.title}>Network Topology</div>
      <div className={classes.legend}>
        <strong>Legend: </strong>
        <Badge variant='green'>Telivy Deployed</Badge>
        <Badge variant='yellow'>Router</Badge>
        <Badge variant='blue'>Other Devices</Badge>
      </div>
      <div className={classes.chartContainer}>{networkTopologyChart}</div>
    </div>
  );
};
